var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WrapperForm',[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('Field',{class:{'field_invalid' : !_vm.validator.fio},attrs:{"label":"Фамилия Имя Отчество","name":"fio","placeholder":"ФИО","type":"text","rusCheck":true},on:{"input":_vm.onChange},model:{value:(_vm.form.fio),callback:function ($$v) {_vm.$set(_vm.form, "fio", $$v)},expression:"form.fio"}}),_c('Field',{class:{'field_invalid' : !_vm.validator.position},attrs:{"label":"Должность","name":"position","placeholder":"Должность","type":"text","rusCheck":true},on:{"input":_vm.onChange},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}}),_c('Field',{class:{'field_invalid' : !_vm.validator.email},attrs:{"label":"Контактный e-mail","name":"email","placeholder":"E-mail","type":"text"},on:{"input":_vm.onChange},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{staticClass:"d-flex direction-column",attrs:{"no-gutters":""}},[_c('v-col',[_c('VuePhoneNumberInput',{staticClass:"mr-2",attrs:{"error":!_vm.validator.workPhoneNumber,"only-countries":['RU'],"translations":{
                countrySelectorLabel: 'Код страны',
                countrySelectorError: 'Неверный код страны',
                phoneNumberLabel: 'Номер рабочего телефона',
                example: 'Пример :'
              },"color":"#546e7a","default-country-code":"RU","error-color":"#842029","valid-color":"#00599B"},on:{"input":function($event){return _vm.inputPhone($event,'workPhoneNumber')},"update":function($event){return _vm.testInputPhoneValue($event, 'workPhoneNumber','workPhoneNumber')}},model:{value:(_vm.form.workPhoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "workPhoneNumber", $$v)},expression:"form.workPhoneNumber"}})],1),_c('v-col',[_c('VuePhoneNumberInput',{attrs:{"error":!_vm.validator.personalPhoneNumber && !_vm.ignorePersonalPhoneNumber,"only-countries":['RU'],"translations":{
                countrySelectorLabel: 'Код страны',
                countrySelectorError: 'Неверный код страны',
                phoneNumberLabel: 'Номер мобильного телефона',
                example: 'Пример :'
              },"color":"#546e7a","default-country-code":"RU","error-color":"#842029","valid-color":"#00599B"},on:{"input":function($event){return _vm.inputPhone($event,'personalPhoneNumber')},"update":function($event){return _vm.testInputPhoneValue($event, 'personalPhoneNumber','personalPhoneNumber')}},model:{value:(_vm.form.personalPhoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "personalPhoneNumber", $$v)},expression:"form.personalPhoneNumber"}})],1)],1),_c('v-row',{staticClass:"d-flex direction-column",attrs:{"no-gutters":""}},[_c('v-col',[_c('button',{class:['button', {'button_disabled': !_vm.isOnButtonSave}],attrs:{"disabled":!_vm.isOnButtonSave,"type":"submit"}},[_vm._v(" Сохранить ")]),_c('button',{staticClass:"button_inline",on:{"click":function($event){$event.preventDefault();return _vm.cancel.apply(null, arguments)}}},[_vm._v("Отмена")])])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }