<template>
  <div :class="['field', customClass]">
    <label class="label" for="name">{{ label }}</label>
    <input
      v-model="$attrs.value"
      :class="['input', 'input_' + type]"
      :name="name"
      :placeholder="placeholder"
      :type="type"
      @change="onValueChanged"
      v-on="$attrs"
    >
  </div>
</template>

<script>
// Важно указывать type, т.к. на клавиатуре смартфона меняет клавиатуру(буквы/цифры)
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    rusCheck: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onValueChanged() {
      // if (!this.rusCheck) {
      this.$emit('input', this.$attrs.value)
      // } else {
      //   this.$emit('input', this.$attrs.value.replace(/[^ аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]/g, ''));
      // }
    }
  }
}

</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"
.input
  color: $greyColor
  font-size: $size-1
  border-bottom: 1px solid $color-g
  height: 45px
  width: 99%
  transition: $speed-0

.input:focus
  border-bottom: 1px solid $color-2
  transition: $speed-0

.input:invalid
  color: red

.input:valid
  color: $color-g
</style>