<template>
  <div>
    <FormKcpContestInfoPersonContact
      v-if="editMode"
      v-model="personToChange"
      :ignore-personal-phone-number="ignorePersonalPhoneNumber"
      @cancel="onCancel"
      v-on:input="requestPersonUpdate($event)"/>
    <div v-else>
      <CardInfo v-if="personInitial && !editMode" :data="personToShow"/>
      <button
        v-if="!blocked && !isSingle"
        class="button info-card__button mr-2"
        @click="addContact">
        Добавить новый контакт
      </button>
      <button v-if="!blocked" class="button info-card__button mr-2" @click="editMode = !editMode">Редактировать</button>
      <button v-if="!blocked && canBeDeleted" class="button info-card__button" @click="deleteContact">Удалить</button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import FormKcpContestInfoPersonContact
  from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoPersonContact';
import CardInfo from '@/components/cards/custom/CardInfo/CardInfo';

export default {
  components: {
    FormKcpContestInfoPersonContact,
    CardInfo
  },
  props: {
    isSingle: {
      type: Boolean,
      default: false
    },
    ignorePersonalPhoneNumber: {
      type: Boolean,
      default: false
    },
    blocked: {
      type: Boolean,
      default: false
    },
    canBeDeleted: {
      type: Boolean,
      default: true
    },
    contact: {
      type: Object,
      default: () => {
        return {
          fio: '',
          fioRodPad: '',
          email: '',
          position: '',
          positionRodPad: '',
          workPhoneNumber: '',
          personalPhoneNumber: '',
        }
      }
    },
  },
  data() {
    return {
      editMode: false,
      personToChange: this.contact,
      personInitial: this.contact,
    }
  },
  watch: {
    contact(newValue) {
      this.personToChange = {...newValue};
      this.personInitial = {...newValue};
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
      isRegion: 'isRegion'
    }),
    get_blockedAll() {
      return false
    },
    personToShow() {
      const {fio, position, email, workPhoneNumber, personalPhoneNumber} = this.contact;
      return {
        'Фамилия Имя Отчество': fio,
        'Должность': position,
        'Контактный e-mail': email,
        'Номер рабочего телефона': workPhoneNumber,
        'Номер мобильного телефона': personalPhoneNumber,
      }
    },
  },
  methods: {
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    addContact() {
      this.$emit('add-contact')
    },
    deleteContact() {
      this.$emit('delete-contact', this.contact)
    },
    async requestPersonUpdate(obj) {
      await this.$emit('change-contact', obj)
      this.editMode = false;
    },
    onCancel() {
      this.personToChange = {...this.personInitial};
      this.editMode = false;
    },
  },
}
</script>

<style lang="sass">
.info-card__button
  margin-top: 10px
  margin-left: 10px
</style>