<template>
  <div class="collapse" :class="{[activeClass]: isOpen && activeClass, [customClass]: customClass}" :isopen="isOpen"
       @click="toggle">
    <div class="collapse__toggle" @click="isOpen = !isOpen">
      <slot name="title">
        <div class="collapse__title">{{ title }}</div>
      </slot>
      <v-icon :size="30" color="#0288D1" class="collapse__icon">
        {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </div>
    <transition name="fade">
      <div class="collapse__content" v-if="isOpen">
        <div ref="collapseContent">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Открыть",
    },
    customClass: {
      type: String,
    },
    activeClass: {
      type: String,
    },
    initialOpen: {
      type: Boolean,
      default: false,
    },
    sprContacts: {
      type: Boolean,
      default: false,
    },
    sprContactsFoiv: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.initialOpen,
    };
  },
  computed: {
    heightCollapsedContent() {
      if (this.isOpen) {
        return this.$refs.collapseContent.clientHeight;
      } else {
        return 0;
      }
    },
  },
  methods: {
    toggle() {
      if (this.isOpen === true && this.sprContacts) {
        this.$emit('get-contact')
      } else if (this.isOpen === true && this.sprContactsFoiv) {
        this.$emit('get-foiv-contact')
      }
    },
  }
};
</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"
.collapse
  background: #fff
  border-radius: 6px
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06)
  margin-bottom: 20px
  padding: 20px

.collapse__content
  height: auto
//overflow: hidden
.fade-enter-active, .fade-leave-active
  transition: opacity .5s

.fade-enter, .fade-leave-to
  opacity: 0

.collapse__toggle
  display: flex
  justify-content: space-between
  align-items: center
  cursor: pointer
//margin-bottom: 8px

.collapse__title
  color: $primary-color
  font-feature-settings: 'pnum' on, 'lnum' on
  font-weight: 600
  font-size: 16px
  line-height: 19px
</style>
