<template>
  <WrapperForm>
    <form class='form' @submit.prevent="onSubmit">
      <Field
        v-model="form.fio"
        :class="{'field_invalid' : !validator.fio}"
        label="Фамилия Имя Отчество"
        name="fio"
        placeholder="ФИО"
        type="text"
        :rusCheck="true"
        @input="onChange"
      />
      <Field
        v-model="form.position"
        :class="{'field_invalid' : !validator.position}"
        label="Должность"
        name="position"
        placeholder="Должность"
        type="text"
        :rusCheck="true"
        @input="onChange"
      />
      <Field
        v-model="form.email"
        :class="{'field_invalid' : !validator.email}"
        label="Контактный e-mail"
        name="email"
        placeholder="E-mail"
        type="text"
        @input="onChange"
      />
      <v-row class="" no-gutters>
        <v-col>
          <v-row class="d-flex direction-column" no-gutters>
            <v-col>
              <VuePhoneNumberInput
                v-model="form.workPhoneNumber"
                :error="!validator.workPhoneNumber"
                :only-countries="['RU']"
                :translations="{
                  countrySelectorLabel: 'Код страны',
                  countrySelectorError: 'Неверный код страны',
                  phoneNumberLabel: 'Номер рабочего телефона',
                  example: 'Пример :'
                }"
                class="mr-2"
                color="#546e7a"
                default-country-code="RU"
                error-color="#842029"
                valid-color="#00599B"
                @input="inputPhone($event,'workPhoneNumber')"
                @update="testInputPhoneValue($event, 'workPhoneNumber','workPhoneNumber')"
              />
            </v-col>
            <v-col>
              <VuePhoneNumberInput
                v-model="form.personalPhoneNumber"
                :error="!validator.personalPhoneNumber && !ignorePersonalPhoneNumber"
                :only-countries="['RU']"
                :translations="{
                  countrySelectorLabel: 'Код страны',
                  countrySelectorError: 'Неверный код страны',
                  phoneNumberLabel: 'Номер мобильного телефона',
                  example: 'Пример :'
                }"
                color="#546e7a"
                default-country-code="RU"
                error-color="#842029"
                valid-color="#00599B"
                @input="inputPhone($event,'personalPhoneNumber')"
                @update="testInputPhoneValue($event, 'personalPhoneNumber','personalPhoneNumber')"
              />
            </v-col>
          </v-row>
          
          <v-row class="d-flex direction-column" no-gutters>
            <v-col>
              <button
                :class="['button', {'button_disabled': !isOnButtonSave}]"
                :disabled="!isOnButtonSave" type="submit"
              >
                Сохранить
              </button>
              <button class="button_inline" @click.prevent="cancel">Отмена</button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </form>
  </WrapperForm>
</template>

<script>
import {email, minLength, required} from 'vuelidate/lib/validators';
import Field from '@/components/fields/Field/Field';
import WrapperForm from '@/components/wrappers/WrapperForm';
import VuePhoneNumberInput from 'vue-phone-number-input/dist/vue-phone-number-input.common';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: {
    Field,
    WrapperForm,
    VuePhoneNumberInput
  },
  data() {
    return {
      isOnButtonSave: false,
      countryCode: '7',
      validator: {
        email: true,
        workPhoneNumber: true,
        personalPhoneNumber: true,
        position: true,
        fio: true
      },
      form: {
        fio: this.$attrs.value.fio,
        position: this.$attrs.value.position,
        email: this.$attrs.value.email,
        workPhoneNumber: this.$attrs.value.workPhoneNumber,
        personalPhoneNumber: this.$attrs.value.personalPhoneNumber,
        personId: this.$attrs.value.personId
      }
    }
  },
  props: {
    ignorePersonalPhoneNumber: {
      type: Boolean,
      default: false
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      workPhoneNumber: {
        required,
      },
      personalPhoneNumber: {
        required,
      },
      position: {
        required,
        minLength: minLength(1),
      },
      fio: {
        required,
        minLength: minLength(1),
      },
    }
  },
  methods: {
    inputPhone(str, variable) {
      if (!str)
        this.form[variable] = ''
      this.onChange()
    },
    testInputPhoneValue(obj, validateVariable, variable) {
      this.form[variable] = obj.formattedNumber;
      this.countryCode = obj.countryCallingCode
      this.validator[validateVariable] = obj.isValid
    },
    onSubmit() {
      this.$v.form.$touch();
      const testFio = this.form.fio.length > 0; // > 10
      const testPosition = this.form.position.length > 0; // > 5
      this.validator.email = !this.$v.form.email.$invalid;
      this.validator.workPhoneNumber = !this.$v.form.workPhoneNumber.$invalid && this.validator.workPhoneNumber;
      this.validator.personalPhoneNumber = !this.$v.form.personalPhoneNumber.$invalid && this.validator.personalPhoneNumber || this.ignorePersonalPhoneNumber;
      this.validator.position = !this.$v.form.position.$invalid && testPosition;
      this.validator.fio = !this.$v.form.fio.$invalid && testFio;
      let check = Object.values(this.validator);
      
      if (check.every(el => el === true)) {
        this.$emit('input', this.form);
      }
    },
    onChange() {
      this.isOnButtonSave = true;
    },
    cancel() {
      this.$emit('cancel');
    }
  }
}
</script>

<style lang="sass">
</style>